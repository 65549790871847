exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-commissions-jsx": () => import("./../../../src/pages/commissions.jsx" /* webpackChunkName: "component---src-pages-commissions-jsx" */),
  "component---src-pages-crochet-patterns-jsx": () => import("./../../../src/pages/crochet-patterns.jsx" /* webpackChunkName: "component---src-pages-crochet-patterns-jsx" */),
  "component---src-pages-esko-jsx": () => import("./../../../src/pages/esko.jsx" /* webpackChunkName: "component---src-pages-esko-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-market-jsx": () => import("./../../../src/pages/market.jsx" /* webpackChunkName: "component---src-pages-market-jsx" */),
  "component---src-templates-crochet-pattern-jsx": () => import("./../../../src/templates/crochet-pattern.jsx" /* webpackChunkName: "component---src-templates-crochet-pattern-jsx" */)
}

